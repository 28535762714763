@import './normalize';

$background-color: #f7f7f7;
$text-color: #111111;

body {
  background-color: $background-color;
  color: $text-color;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI',
    'Hiragino Kaku Gothic ProN', 'Yu Gothic', sans-serif;
}
.container {
  margin: 0 auto;
  max-width: 700px;
  padding: 40px 20px;
}
.loading {
  text-align: center;
}
.loading-image {
  $size: 40px;
  display: inline-block;
  height: $size;
  width: $size;
}
.text {
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
}
